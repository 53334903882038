import React from "react"

export default function FAQ() {
  return (
    <div>
      <h1>About CSS Modules</h1>
      <p>CSS Modules are cool</p>
    </div>
  )
}
